import { defineComponent, reactive, toRefs, onMounted, nextTick } from "vue";
import { useRoute, useRouter } from "vue-router";
import { getSerchList } from "@/http/serch";
import { imgUrl } from "@/utils/urlManage";
import emitter from "@/mitt";
export default defineComponent({
  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const data = reactive({
      pageNo: 1,
      pageSize: 10,
      total: 0,
      serchList: [],
      text: ""
    });
    const methods = {
      /**
       * 点击搜索列表某一项跳转到对应页面
       * @param row
       */
      goSerch(row) {
        if (row.from == "join") {
          router.push(`/hr?id=${row.code}&onlyName=${row.joinTitle}&subtitle=${row.joinSubtitle}`);
        } else if (row.from == "customColumns") {
          router.push(`/columnDisplay?partCode=${row.partCode}&code=${row.code}`);
        } else if (row.from == "service") {
          router.push({
            path: `/deviceDetailsByCode?code=${row.code}`,
            query: {
              code: row.code
            }
          });
        } else if (row.from == "contentNews") {
          router.push({
            path: `/newsDetail?id=${row.code}`,
            query: {
              id: row.code
            }
          });
        }
      },

      /**
       * 返回上一个页面
       */
      goBack() {
        router.back();
      },

      /**
       * 获取搜索结果
       */
      getList() {
        getSerchList({
          orgCode: sessionStorage.getItem("orgCode") || "",
          queryText: route.query.text,
          pageNo: data.pageNo,
          pageSize: data.pageSize
        }).then(res => {
          if (res.data.code == 200) {
            var _document$getElementB;

            data.serchList = res.data.data.data;
            data.text = `搜索关健字“${route.query.text}”，共有${res.data.data.total}条记录`; // data.text = res.data.data.dataImg;

            data.total = res.data.data.total;
            (_document$getElementB = document.getElementById("news")) === null || _document$getElementB === void 0 ? void 0 : _document$getElementB.scrollIntoView({
              // behavior: "smooth",
              block: "end"
            });
          } else {
            data.serchList = [];
            data.text = "";
            data.total = 0;
          }
        });
      },

      // 切换分页大小
      handleSizeChange(val) {
        data.pageSize = val;
        methods.getList();
      },

      // 切换页码时触发
      handleCurrentChange(val) {
        data.pageNo = val;
        methods.getList();
      }

    };
    onMounted(() => {
      methods.getList();
    }), emitter.on("pro-serch", () => {
      nextTick(() => {
        data.serchList = [];
        data.text = "";
        data.total = 0;
        console.log("wwwwwwww");
        let timer = setTimeout(() => {
          methods.getList();
          console.log(11);
          clearTimeout(timer);
        }, 200);
      });
    });
    return { ...toRefs(data),
      ...methods,
      route,
      imgUrl
    };
  }

});